/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
	width:75px;
  -webkit-transition: width .25s ease-in-out;
  -moz-transition: width .25s ease-in-out;
  -o-transition: width .25s ease-in-out;
  transition: width .25s ease-in-out;

}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

/* #sidebar-wrapper .list-group { */
  /* width: 15rem; */
/* } */

/* #page-content-wrapper {
  min-width: 100vw;
} */
#page-content-wrapper {
  min-width: 0;
  width: 100%;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
   width:75px;
  }



  #wrapper.toggled #sidebar-wrapper {
    width:250px;
  }
}

.sidebar-list{
  position: relative;
  overflow: hidden;
}
.sidebar-list:hover{
  overflow: visible;
}
 .sidebar-link{
  display: flex;
  flex-wrap: nowrap;
  padding: 15px 20px;
  width: 75px;
  position: relative;
  z-index: 99;
  text-align: center;
}
.sidebar-link i{
font-size: 24px;
position: absolute;
transition: 0.3s ease-out;
z-index: 99;
color: #d4dee8;
width: 30px;
}
.sidebar-link span{
  font-size: 14px;
  opacity: 1;
  transform: translateX(65px);
  -webkit-transform: translateX(65px);
  white-space: nowrap;
  padding-left: 0px;
  opacity: 0;
}

.sidebar-list:hover .sidebar-link{

  width:210px;
  background: #f1f1f1;
}

.sidebar-list:hover .sidebar-link span{
  opacity: 1;
}

.toggled .sidebar-link{
  justify-content: left;
}
.toggled .sidebar-link i{
font-size: 18px;
width:25px;
text-align: center;

}
.toggled .sidebar-link span{
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  padding-left: 30px;
  opacity: 1;

}

.sidebar-link:hover,.sidebar-link:focus,.sidebar-link:hover i,.sidebar-link:focus i{
  color:#6c63ff;
}
.sidebar-dropdown{
  transform: translateX(45%);
  -webkit-transform: translateX(45%);
  position: absolute;
  left: 0px;
  background: #fff;
  /* padding: 0px 10px ; */
  width: 145px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  padding: 15px 0px 15px 30px;
  z-index: 999;
}

.sidebar-list:hover .sidebar-dropdown{
  opacity: 1;
}
.sidebar-dropdown-link{
  font-size: 14px;
  padding: 10px 0px;
  display: inline-block;
  transition: 0.3s ease-in-out;
  width:100%;
}
.sidebar-dropdown-link:hover{
  color: #8195ab;
}

.toggled .sidebar-list  .sidebar-dropdown
{
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.toggled .sidebar-list:hover  .sidebar-dropdown{
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  width:100%;
  padding-left: 65px;
  padding-right: 0px;
  position: relative;
}
/* head bar */

.name-block{
  text-align: right;
  padding: 0px 25px;
  font-size: 14px;
  color: #8195AB;
}
.avatar-md{
  width: 65px;
  /* height: 65px; */
  padding: 0px;
}
/* .header-profile-user{
  max-width:100%;
  padding: .25rem;
    background-color: #f2f2ff;
    border: 1px solid #f2f2ff;
} */

.header-profile-user {
    max-width: 85%;
    padding: 0.25rem;
    background-color: #f2f2ff;
    border: 1px solid #f2f2ff;
    height: 50px;
}

